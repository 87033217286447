import { Button, Card, Input } from 'antd';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiCall } from '../../API/RestApi';
import { modalError, modalSuccess } from '../../Component/Modal/ModalLayout';

function ChangePassword() {
    const [password, setPassword] = useState();
    const [rePassword, setRePassword] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    //FUNCTION 
    const sendAPI = (data) => {
        const dataReq = {
            method: "POST",
            url: '/changepassword',
            data: {
                data: data,
                headers: null
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                modalSuccess('Berhasil');
                navigate('/login');
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            modalError(e.message);
        })
    }

    const send = () => {
        if (password === rePassword) {
            var user_id = location.pathname.split('/')[2]
            var data = {
                user_id: user_id,
                password: password
            }
            sendAPI(data);
        }
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Card>
                <h2>Ganti Kata Sandi </h2>
                <div>Kata Sandi</div>
                <Input.Password
                    placeholder="Masukkan Kata Sandi Anda"
                    onChange={(val) => setPassword(val.target.value)}
                    style={{
                        marginBottom: 10
                    }}
                    value={password}
                />
                <div>Konfirmasi Kata Sandi</div>
                <Input.Password
                    placeholder="Konfirmasi Kata Sandi Anda"
                    onChange={(val) => setRePassword(val.target.value)}
                    style={{
                        marginBottom: 10
                    }}
                    value={rePassword}
                />
                <div style={{ float: 'right' }}>
                    <Button type='primary' onClick={send}>Ganti Kata Sandi</Button>
                </div>
            </Card>
        </div>
    );
}


export default ChangePassword;