
import { Alert, Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Switch, Table, TreeSelect } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';


function ListOutletAkun() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState();
    const [outlet, setOutlet] = useState([]);
    const [akun, setAkun] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [form] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [count, setCount] = useState(akun.length || 0);

    // //Parameter
    const titlePage = 'Akun Pembayaran';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Outlet',
            dataIndex: 'outlet_name',
            key: "outlet_name",
            render: (text, record) => {
                return record.outlet_name;
            }
        },
        {
            title: 'Akun Perkiraan',
            dataIndex: 'akun_name',
            key: "akun_name",
            render: (text, record) => {
                return <p>{
                    record.outlet_akuns.map((key, i) => (
                        <div key={i}>{key.akun.akun_name}</div>
                    ))
                }</p>;
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        {/* <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button> */}
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi("GET", '/outletakundata?search=' + search + '&page=' + page.pagination.current, 'outletakun');
        getApi("GET", 'outlet?nopage=true', 'outlet');
        getApi("GET", '/akun?nopage=true&search=EDC&search2=kas', 'akun');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'outletakun':
                        setData(res.data.rows);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.count
                            }
                        });
                        setLoading(false);
                        break;
                    case 'outlet':
                        var item = res.data;
                        var row = [];
                        for (let i = 0; i < item.length; i++) {
                            row.push({
                                value: item[i].outlet_id,
                                label: item[i].outlet_name,
                            })
                        }
                        setOutlet(row);
                        setLoading(false);
                        break;
                    case 'akun':
                        var item2 = res.data.rows;
                        var row2 = [];
                        for (let i = 0; i < item2.length; i++) {
                            row2.push({
                                value: item2[i].akun_id,
                                label: item2[i].akun_name,
                            })
                        }
                        setAkun(row2);
                        setLoading(false);
                        break;

                    default:
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = (method, values) => {
        setLoading(true);
        setIsAlert(false);
        var url = '/outletakun';
        if (method === 'DELETE') {
            url = '/outletakun/' + values.outlet_akun_id;
        }
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    // const modalDeleteData = (v) => {
    //     Modal.confirm({
    //         title: 'NOTICE',
    //         content: 'Anda ingin menghapus data ini?',
    //         onOk: () => postApi('DELETE', v),
    //     });
    // }

    const openModal = (type, data) => {
        if (type === 'edit') {
            setdataModal(data);
            var o = [];
            for (let i = 0; i < data.outlet_akuns.length; i++) {
                var v = data.outlet_akuns[i];
                o.push({
                    key: i,
                    akun_id: v.akun_id,
                    status_paid: v.status_paid,
                });
            }
            setDataSource(o);
            setCount(data.outlet_akuns.length);
            form.setFieldsValue({
                outlet_id: data.outlet_id,
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (ev) => {
        ev.akun = dataSource;
        console.log(ev)
        if (dataModal !== null) {
            postApi('PUT', ev);
        } else {
            postApi('POST', ev);
        }
    }

    const closeModal = () => {
        setDataSource([]);
        setCount(0);
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }


    // Function to add a new row
    const handleAdd = () => {
        const newData = {
            key: count,
            akun_id: null,
            status_paid: true,
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
    };

    // Function to handle row changes
    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
            setDataSource(newData);
        }
    };

    // Function to delete a row
    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
    };

    // Define columns for the table
    const columnAkun = [
        {
            title: 'Akun Perkiraan',
            dataIndex: 'akun_id',
            render: (text, record) => (
                <Select
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) =>
                        handleSave({ ...record, akun_id: e })
                    }
                    value={record.akun_id}
                    placeholder="Akun perkiraan"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={akun}
                />
            ),
        },
        {
            title: 'Status Lunas',
            dataIndex: 'status_paid',
            render: (text, record) => (
                <Switch
                    checked={record.status_paid}
                    onChange={(checked) => handleSave({ ...record, status_paid: checked })}
                />
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title="ingin menghapus data ini?" onConfirm={() => handleDelete(record.key)}>
                        <Button type="link" danger>
                            Delete
                        </Button>
                    </Popconfirm>
                ) : null,
        },
    ];

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Akun Pembayaran</h3>
            <Row>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Akun Pembayaran</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Form Data" width={700} open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form_outlet_akun" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="outlet_id"
                        label="Nama Outlet"
                        rules={[
                            {
                                required: true,
                                message: 'Nama Outlet kosong!'
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: '100%',
                            }}
                            placeholder="Akun perkiraan"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={outlet}
                        />
                    </Form.Item>
                    {/* <Form.Item
                        name="akun"
                        label="Akun Perkiraan"
                        rules={[
                            {
                                required: false,
                                message: 'Akun Perkiraan kosong!'
                            },
                        ]}
                    >
                        <TreeSelect
                            showSearch
                            autoClearSearchValue={false}
                            style={{
                                width: '100%',
                            }}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            treeCheckable={true}
                            placeholder="Pilih Akun Perkiraan"
                            treeCheckStrictly={true}
                            treeNodeFilterProp='title'
                            treeData={akun}
                        />
                    </Form.Item> */}

                    <Table
                        columns={columnAkun}
                        dataSource={dataSource}
                        pagination={false}
                        rowKey="key"
                        footer={() => (
                            <Button onClick={handleAdd} type="primary">
                                Tambah Akun Perkiraan
                            </Button>
                        )}
                    />
                </Form>
            </Modal>
        </>
    );
}

export default ListOutletAkun;