import { Button, Card, Col, Form, Input, Row, } from 'antd';
import { UserOutlined, EyeTwoTone, EyeInvisibleOutlined, LockOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { useNavigate } from 'react-router';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useDispatch } from 'react-redux';
import { MENU_USER } from '../../Redux/actions/userActions';

function Login() {
    //LIBRARY
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //FUNCTION 
    const onLogin = (values) => {
        const dataReq = {
            method: "POST",
            url: '/login',
            data: {
                data: values,
                headers: null
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                var menudata = res.data.data.user_role;
                // menudata.user_role_menus.sort((a, b) => (a.menu.sequence > b.menu.sequence) ? 1 : ((b.menu.sequence > a.menu.sequence) ? -1 : 0));
                dispatch(
                    MENU_USER(
                        JSON.stringify(menudata),
                        JSON.stringify(res.data.data),
                        res.data.token
                    )
                )
            
                var menu = res.data.data.user_role.user_role_menus;
                var menu_selected;
                var index = null;
                for (let i = 0; i < menu.length; i++) {
                    if(index !== null){
                        if(menu[index].menu.sequence < menu[i].menu.sequence){
                            menu_selected = menu[index].menu.link;
                        }else{
                            menu_selected = menu[i].menu.link;
                        }
                    }else{
                        index = i;
                        menu_selected = menu[i].menu.link;
                    }
                }
                navigate('/'+menu_selected);

            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            console.log(e)
            modalError('(Internal Server Error)');
        })
    }

    const onFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Card>
                <h2>Login Point Of Sales</h2>
                <Form
                    name="login"
                    onFinish={onLogin}
                    onFinishFailed={onFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="user"
                        rules={[{ required: true, message: 'Username / E-mail anda kosong!' }]}
                    >
                        <Input
                            placeholder="Username atau E-mail"
                            prefix={<UserOutlined />}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Password anda kosong!' }]}
                    >
                        <Input.Password
                            placeholder="input password"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            prefix={<LockOutlined />}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Button type='primary' htmlType="submit">Login</Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}


export default Login;