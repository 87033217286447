
import { Alert, Button, Col, Modal, Row, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined, SyncOutlined, LoadingOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../Utils/formatCurrency';


function ListProduct() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Produk';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kode Barang',
            dataIndex: 'product_code',
            key: "product_code",
            sorter: true,
        },
        {
            title: 'Barcode',
            dataIndex: 'barcode',
            key: "barcode",
            sorter: true,
            render: (text, record) => {
                if (record.variant_products.length > 0)
                    return record.variant_products[0].barcode_code !== null ? record.variant_products[0].barcode_code : '-';
                else
                    return '-';
            }
        },
        {
            title: 'Nama Barang',
            dataIndex: 'product_name',
            key: "product_name",
            sorter: true,
        },
        {
            title: 'Kategori',
            dataIndex: 'category',
            key: "category",
            sorter: true,
        },
        {
            title: 'Harga',
            dataIndex: 'price',
            key: "price",
            sorter: true,
            render: (text, record) => {
                if (record.variant_products.length > 0)
                    return currencyFormat(record.variant_products[0].price);
                else
                    return '-';
            }
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: "isActive",
            sorter: true,
            render: (text, record) => {
                return (
                    <>
                        {
                            record.isActive === true ?
                                <b>Aktif</b>
                                :
                                <b>Tidak Aktif</b>
                        }
                    </>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => navigate('/formproduct', { state: { item: record } })}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi('/product?search=' + search + '&page=' + page.pagination.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (url) => {
        setLoading(true);
        const dataReq = {
            method: "GET",
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                var row = [];
                var item = res.data.rows;
                for (let i = 0; i < item.length; i++) {
                    //Satuan
                    var product_satuan = item[i].product_satuan;
                    var satuan = product_satuan != null ? product_satuan.satuan : null;
                    var rasio_satuan = product_satuan != null ? product_satuan.rasio_satuan : null;
                    row.push({
                        product_id: item[i].product_id,
                        product_code: item[i].product_code,
                        product_name: item[i].product_name,
                        category: item[i].category != null ? item[i].category.category_name : null,
                        category_id: item[i].category != null ? item[i].category.category_id : null,
                        satuan: satuan,
                        rasio_satuan: rasio_satuan,
                        description: item[i].description,
                        isActive: item[i].isActive,
                        image: item[i].image,
                        variant_products: item[i].variant_products
                    })
                }
                setData(row);
                setPage({
                    ...page,
                    pagination: {
                        ...page.pagination,
                        total: res.data.count
                    }
                });
                setLoading(false);
                setLoadingBtn(false);
            } else {
                setLoading(false);
                setLoadingBtn(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            setLoadingBtn(false);
            console.log(e.message);
        })
    }

    const postApi = (method, values) => {
        setLoading(true);
        setIsAlert(false);

        var url = '/product';
        if (method === 'DELETE') {
            url = '/product/' + values.product_id;
        }
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', v),
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const syncProductAccurate = () => {
        setLoadingBtn(true);
        setLoading(true);
        getApi('/accurate/loaditem?page=1&pageSize=100');
    }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Produk</h3>
            <Row>
                <Col span={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button style={{ marginBottom: 10, marginRight: 10 }} type="primary" onClick={() => syncProductAccurate()}>
                        {
                            loadingBtn === true ?
                                <LoadingOutlined />
                                :
                                <SyncOutlined />
                        }
                        Sync Accurate
                    </Button>
                    <Search
                        disabled={loadingBtn}
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>

                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button disabled={loadingBtn} style={{ marginBottom: 10 }} type="primary" onClick={() => { navigate('/formproduct') }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Produk</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    );
}

export default ListProduct;