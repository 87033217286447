import { Button } from "antd";
import { PrinterOutlined, NodeIndexOutlined } from '@ant-design/icons';
import { Cut, Printer, Text, render } from 'react-thermal-printer';
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_PORT } from "../../Redux/actions/printActions";
// import {PosPrinter } from "electron-pos-printer";
// import * as path from "path";

function SetPrinter() {

    const serialPort = useSelector(state => state.print.port);
    const dispatch = useDispatch();

    const receipt = (
        <Printer type="epson" width={32}>
            <Text align="center">TESTING BERHASIL</Text>
            <Cut />
        </Printer>
    );

    const checkDevices = async() => {
        const port = await window.navigator.serial.requestPort();
        await port.open({ baudRate: 9600 });
        dispatch(UPDATE_PORT(port));
    }

    const testPrint = async () => {
        const data = await render(receipt);
        var ports = serialPort;
        if (serialPort === null) {
            const port = await window.navigator.serial.requestPort();
            await port.open({ baudRate: 9600 });
            ports = port;
            dispatch(UPDATE_PORT(port));
        }
        const w = ports.writable?.getWriter();
        if (w != null) {
            await w.write(data);
            w.releaseLock();
        }
    }


    return (
        <>
            <h3>Pengaturan Printer</h3>
            <Button onClick={() => checkDevices()} type="dashed" style={{ marginRight: 10 }}><NodeIndexOutlined />Pilih Port Printer</Button>
            <Button onClick={() => testPrint()} type="dashed" style={{ marginRight: 10 }}><PrinterOutlined />Test Print</Button>
        </>
    );
}

export default SetPrinter