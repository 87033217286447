import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import ProtectedLayout from "../Component/Main/ProtectedLayout";
import ChangePassword from "../Views/Auth/ChangePassword";
import CheckPinCode from "../Views/Auth/CheckPinCode";
import Login from '../Views/Auth/Login';
import ListCategory from "../Views/Category/ListCategory";
import Dashboard from "../Views/Dashboard/Dashboard";
import Employee from "../Views/Employee/Employee";
import ImportFile from "../Views/Import/ImportFile";
import NotFound from "../Views/NotFound/NotFound";
import FormOutlet from "../Views/Outlet/FormOutlet";
import ListOutlet from "../Views/Outlet/ListOutlet";
import UserOutlet from "../Views/POS/Outlet/UserOutlet";
import POS from "../Views/POS/POS";
import SetPrinter from "../Views/Printer/SetPrinter";
import ListProduct from "../Views/Product/ListProduct";
import Profile from "../Views/Profile/Profile";
import OutletReport from "../Views/Report/OutletReport";
import RoleUser from "../Views/RoleUser/RoleUser";
import VerificationPoint from "../Views/VerificationPoint/VerificationPoint";
import Membership from "../Views/Membership/Membership";
import InputVerification from "../Views/InputVerification/InputVerification";
import ListTransactionReport from "../Views/Report/ListTransactionReport";
import ListWarehouse from "../Views/Warehouse/ListWarehouse";
import FormDataProduct from "../Views/Product/Product/FormDataProduct";
import SetOutletProduct from "../Views/SetOutletProduct/SetOutletProduct";
import SetOutletProductVariant from "../Views/SetOutletProduct/SetOutletProductVariant";
import ListAkun from "../Views/Akun/ListAkun";
import ListPriceCategory from "../Views/PriceCategory/ListPriceCategory";
import ListOutletAkun from "../Views/OutletAkun/ListOutletAkun";
import ListOutletCategory from "../Views/PriceCategory/Outlet/ListOutletCategory";
import ListItemTransfer from "../Views/ItemTransfer/ListItemTransfer";
import ProductPackage from "../Views/ProductPackage/ProductPackage";
import FormProductPackage from "../Views/ProductPackage/FormProductPackage";
import ListGroupProductCategory from "../Views/PriceCategory/Product/ListGroupProductCategory";
import FormProductCategory from "../Views/PriceCategory/Product/FormProductCategory";
import ListVoucher from "../Views/Voucher/ListVoucher";
import ProductPromo from "../Views/ProductPromo/ProductPromo";
import FormProductPromo from "../Views/ProductPromo/FormProductPromo";
import DisplayScreen from "../Views/Display/DisplayScreen";
import CategoryBonus from "../Views/CategoryBonus/CategoryBonus";
import FormCategoryBonus from "../Views/CategoryBonus/FormCategoryBonus";
import ProductReturn from "../Views/ProductReturn/ProductReturn";
import Invoice from "../Views/TransactionInvoice/Invoice";
import ProductPrice from "../Views/ProductPrice/ProductPrice";
import FormProductPrice from "../Views/ProductPrice/FormProductPrice";
import DataDisplay from "../Views/Display/DataDisplay/DataDisplay";

function Router({ history }) {
  return (
    // <BrowserRouter history={history}>
    <HashRouter history={history}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/changepassword/:id" element={<ChangePassword />} />
        <Route path="/checkpincode/:id" element={<CheckPinCode />} />
        <Route path="/display/:outletId" element={<DisplayScreen />} />
        <Route path="/" element={<ProtectedLayout />} >
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/listoutlet" element={<UserOutlet />} />
          <Route path="/pos" element={<POS />} />
          <Route path="/category" element={<ListCategory />} />
          <Route path="/outlet" element={<ListOutlet />} />
          <Route path="/formoutlet" element={<FormOutlet />} />
          <Route path="/product" element={<ListProduct />} />
          <Route path="/formproduct" element={<FormDataProduct />} />
          <Route path="/user" element={<Employee />} />
          <Route path="/userRole" element={<RoleUser />} />
          <Route path="/printer" element={<SetPrinter />} />
          <Route path="/outletReport" element={<OutletReport />} />
          <Route path="/listTransactionReport" element={<ListTransactionReport />} />
          <Route path="/verifyPoint" element={<VerificationPoint />} />
          <Route path="/importFile" element={<ImportFile />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/inputVerify" element={<InputVerification />} />
          <Route path="/warehouse" element={<ListWarehouse />} />
          <Route path="/setProdukOutlet" element={<SetOutletProduct />} />
          <Route path="/setProdukVariantOutlet" element={<SetOutletProductVariant />} />
          <Route path="/akun" element={<ListAkun />} />
          <Route path="/priceCategory" element={<ListPriceCategory />} />
          <Route path="/setAkunOutlet" element={<ListOutletAkun />} />
          <Route path="/outletCategory" element={<ListOutletCategory />} />
          <Route path="/productCategory" element={<ListGroupProductCategory />} />
          <Route path="/formProductCategory" element={<FormProductCategory />} />
          <Route path="/itemTransfer" element={<ListItemTransfer />} />
          <Route path="/productPackage" element={<ProductPackage />} />
          <Route path="/formProductPackage" element={<FormProductPackage />} />
          <Route path="/voucher" element={<ListVoucher />} />
          <Route path="/productPromo" element={<ProductPromo />} />
          <Route path="/formProductPromo" element={<FormProductPromo />} />
          <Route path="/categoryBonus" element={<CategoryBonus />} />
          <Route path="/formCategoryBonus" element={<FormCategoryBonus />} />
          <Route path="/productReturn" element={<ProductReturn />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/productPrice" element={<ProductPrice />} />
          <Route path="/formProductPrice" element={<FormProductPrice />} />
          <Route path="/dataDisplay" element={<DataDisplay />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </HashRouter>
    // {/* </BrowserRouter> */}
  );
}


export default Router;