import { Card, Col, Row, Spin, Statistic, Table } from "antd"
import { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Helmet } from "react-helmet-async";
import { apiCall } from "../../API/RestApi";
import { Chart, registerables } from 'chart.js';
import { useSelector } from "react-redux";
import { dateFormat } from "../../Utils/formatDate";
Chart.register(...registerables);

const titlePage = 'Dashboard';

function Dashboard() {
  const token = useSelector((state) => state.user.token);
  //parameter
  const [data, setData] = useState([]);
  const [dataActivity, setDataActivity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLine, setDataLine] = useState({
    labels: [],
    datasets: [
      {
        label: 'Kemarin',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Hari ini',
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  });

  const options = {
    responsive: true,
    plugins: {
      // legend: {
      //   position: 'top',
      // },
      title: {
        display: true,
        text: 'Pendapatan Hari ini',
      },
    },
  };

  const columnActivity = [
    {
      title: 'No',
      dataIndex: 'no',
      sorter: true,
      key: "no",
      render: (id, record, index) => { ++index; return index },
    },
    {
      title: 'Tanggal',
      dataIndex: 'created_at',
      key: "created_at",
      render: (id, record, index) => {
        return dateFormat(record.created_at);
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: "username",
      render: (id, record, index) => {
        if (record.user !== null) {
          return record.user.username;
        }
      },
    },
    {
      title: 'Menu',
      dataIndex: 'menu',
      key: "menu",
    },
    {
      title: 'Deskripsi',
      dataIndex: 'desc',
      key: "desc",
    },
  ];

  useEffect(() => {
    getApi('GET', '/analysis', 'analysis');
    getApi('GET', '/useractivity', 'activity');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApi = (method, url, type) => {
    setLoading(true);
    const dataReq = {
      method: method,
      url: url,
      data: {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    };
    apiCall(dataReq).then((res) => {
      if (res.status === 200) {
        switch (type) {
          case 'analysis':
            setData(res.data);
            var labels = [];
            var data_before = [];
            var data_now = [];

            // Mengambil data dari res.data.outlet_before
            res.data.outlet_before.forEach(outlet => {
              labels.push(outlet.outlet_name);
              data_before.push(outlet.total_grand_total);

              // Inisialisasi data_now dengan nilai 0 untuk setiap outlet
              data_now.push(0);
            });

            // Memproses data dari res.data.outlet_now jika ada
            if (res.data.outlet_now.length > 0) {
              res.data.outlet_now.forEach(outlet => {
                // Cari indeks outlet di data_before berdasarkan outlet_id
                const index = res.data.outlet_before.findIndex(item => item.outlet_id === outlet.outlet_id);

                // Jika ditemukan, perbarui nilai data_now di indeks yang sesuai
                if (index !== -1) {
                  data_now[index] = outlet.total_grand_total;
                }
              });
            }
            
            var dl = {
              labels,
              datasets: [
                {
                  label: 'Kemarin',
                  data: data_before,
                  borderColor: 'rgb(255, 99, 132)',
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                  label: 'Hari ini',
                  data: data_now,
                  borderColor: 'rgb(53, 162, 235)',
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
              ],
            };

            setDataLine(dl);
            setLoading(false);
            break;
          case 'activity':
            setDataActivity(res.data.rows);
            setLoading(false);
            break;

          default:
            setLoading(false);
            break;
        }
      } else {
        setLoading(false);
        console.log(res.message);
      }

    }).catch((e) => {
      setLoading(false);
      console.log(e.message);
    })
  }

  return (
    <>
      <Helmet>
        <title>{titlePage}</title>
      </Helmet>
      {
        loading === false ?
          <>
            <Row gutter={16}>
              <Col span={8}>
                <Card bordered={false}>
                  <Statistic
                    title="Total Penjualan Kemarin"
                    value={data.before !== undefined ? Number(data.before.total_price_transaction) : 0}
                    valueStyle={{ color: '#888' }}
                    prefix={'Rp. '}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card bordered={false}>
                  <Statistic
                    title="Total Penjulan Hari Ini"
                    value={data.now !== undefined ? Number(data.now.total_price_transaction) : 0}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={'Rp. '}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card bordered={false}>
                  <Statistic
                    title="Total Customer Terdaftar"
                    value={data.total_customer}
                    valueStyle={{ color: '#d6006e' }}
                  />
                </Card>
              </Col>
            </Row>
            <Bar options={options} data={dataLine} />
            <hr />
            <h3 style={{ marginTop: 10 }}>Aktivitas User</h3>
            <Table
              size="small"
              columns={columnActivity}
              dataSource={dataActivity}
              loading={loading}
            />
          </>
          :
          <Spin tip="Loading">
            {/* <div className="content" /> */}
          </Spin>
      }
    </>
  );
};
export default Dashboard;