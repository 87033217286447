
import { Br, Cut, Line, Printer, Text, Row, render, Image } from 'react-thermal-printer';
import { currencyFormatPrint } from './formatCurrency';
import { dateFormat } from './formatDate';
import { posPriceBeforeDiscountNum, posPriceDiscountPercent, posPriceDiscountPrice, posPriceNum } from './POS/posPrice';
import { textPaper } from './Print/printText';


const bonusPCS = (item) => {
    var total_qty = item.qty;
    var qty_bonus = total_qty / 2;
    var qty_now = item.bonus.bonus_product.qty * qty_bonus;
    return Math.floor(qty_now);
}

const textRight = (text) => {
    var n = text.substring(0, 20);
    return n;
}

const jml_paper = 32;

export const writePrint = async (serialPort, ev) => {
    var date = new Date();
    var dateTime = dateFormat(date);
    var customer = ev.customer !== undefined && ev.customer !== null ? ev.customer.name : 'Non Membership';
    // var total_qty = 0;
    const receipt = (
        <Printer type="epson" width={jml_paper}>
            <Image align="center" src="assets/bolde2.png" />
            <Br />
            <Text size={{ width: 1, height: 1 }} bold={true} align="center">{textPaper(ev.outlet.outlet_name, jml_paper)}</Text>
            <Text align="center">{ev.outlet.address !== "" && ev.outlet.address !== null ? textPaper(ev.outlet.address, jml_paper) : "Komp Era Prima No G1 Jl Daan Mogot KM 21, Tangerang"}</Text>
            <Text bold={true} align="center">{ev.transaction_code}</Text>
            <Br />
            <Line />
            <Row left="Kasir     : " right={textRight(ev.cashier.detail_user.first_name + ' ' + ev.cashier.detail_user.last_name)} />
            <Row left="Transaksi : " right={dateTime} />
            <Row left="Pelanggan : " right={textRight(customer)} />
            <Line />
            {
                ev.product.map((key, i) => {

                    var price = posPriceNum(key);
                    var discount_percent = posPriceDiscountPercent(key);
                    var discount_price = Number(posPriceDiscountPrice(key));
                    var subtotal = Number(price) * key.qty;

                    if (key.edit_price !== undefined) {
                        price = key.edit_price;
                        discount_percent = key.edit_discount_percent;
                        discount_price = key.edit_discount_price;
                        subtotal = Number(price) * key.qty;
                    }
                    var productName = key.product.product_name + ' ' + (key.variant_name != null ? key.variant_product.variant_name : '');
                    return (
                        <div key={i}>
                            {
                                key.status_bonus !== undefined && key.status_bonus !== null ?
                                    key.status_bonus === true ?
                                        <>
                                            <Text bold={true}>{'(Bonus) ' + key.product.product_name + ' ' + (key.variant_name != null ? key.variant_product.variant_name : '')}</Text>
                                            <Row left={key.qty + (key.product.product_satuans.length > 0 ? key.product.product_satuans[0].satuan : '') + " x " + currencyFormatPrint(0)} right={currencyFormatPrint(0)} />
                                        </>
                                        :
                                        null
                                    :
                                    <>
                                        <Text bold={true}>{textPaper(productName, jml_paper)}</Text>
                                        <Row left={key.qty + (key.product.product_satuans.length > 0 ? key.product.product_satuans[0].satuan : '') + " x " + currencyFormatPrint(price)} right={currencyFormatPrint(subtotal)} />
                                        <Row left="Diskon" right={currencyFormatPrint(discount_price) + '(' + discount_percent + ')'} />
                                        {
                                            key.bonus !== undefined && key.bonus !== null ?
                                                key.qty >= key.bonus.min_order ?
                                                    key.bonus.bonus_product !== null ?
                                                        <>
                                                            <Text bold={true}>{'(Bonus) ' + key.bonus.bonus_product.variant_product.product.product_name + ' ' + key.bonus.bonus_product.variant_product.variant_name}</Text>
                                                            <Row left={bonusPCS(key) + (key.bonus.bonus_product.variant_product.product.product_satuan !== null ? key.bonus.bonus_product.variant_product.product.product_satuan.satuan : '') + " x " + currencyFormatPrint(0)} right={currencyFormatPrint(0)} />
                                                        </>
                                                        :
                                                        null
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                    </>
                            }
                            {ev.product.length < (i + 1) && <Br />}
                        </div>
                    )
                })
            }
            <Line />
            <Text size={{ width: 1.1, height: 1.1 }} bold={true} align="right" >Total : {currencyFormatPrint(ev.data.grand_total)}</Text>
            <Text bold={true} align="right">+ Termasuk Pajak</Text>
            <Line />
            {
                ev.data.payment_method === 'tunai' ?
                    <Row left="TUNAI : " right={currencyFormatPrint(ev.data.payment)} />
                    :
                    ev.data.payment_method === 'tunai_point' || ev.data.payment_method === 'point' ?
                        <>
                            <Row left="TUNAI : " right={currencyFormatPrint(ev.data.payment)} />
                            <Row left="POINT : " right={"-"+currencyFormatPrint(ev.data.point_redeem)} />
                        </>
                        :
                        ev.data.payment_method === 'card_point' ?
                            <>
                                <Row left={ev.data.payment_name + ' :'} right={currencyFormatPrint(ev.data.payment)} />
                                <Row left="POINT : " right={"-"+currencyFormatPrint(ev.data.point_redeem)} />
                            </>
                            :
                            <Row left={ev.data.payment_name + ' :'} right={currencyFormatPrint(ev.data.grand_total)} />
            }
            {
                ev.data.voucher_price !== null && ev.data.voucher_price !== 0 ?
                    <Row left={'Voucher :'} right={currencyFormatPrint(ev.data.voucher_price)} />
                    :
                    null
            }
            <Line />
            <Row left="Kembali : " right={currencyFormatPrint(ev.data.cashback)} />
            <Line />
            <Text align="center">Barang yang sudah dibeli tidak</Text>
            <Text align="center">bisa ditukar / dikembalikan</Text>
            <Line />
            <Text align="center">Website : www.boldestore.com</Text>
            <Cut />
        </Printer>
    );
    const data = await render(receipt);
    const w = serialPort.writable?.getWriter();
    if (w != null) {
        await w.write(data);
        w.releaseLock();
    }
}

export const writePrintTry = async (serialPort, ev) => {
    var date = new Date();
    var dateTime = dateFormat(date);
    var customer = ev.customer !== undefined && ev.customer !== null ? ev.customer.name : 'Non Membership';
    // var total_qty = 0;
    const receipt = (
        <Printer type="epson" width={jml_paper}>
            <Image align="center" src="assets/bolde2.png" />
            <Br />
            <Text size={{ width: 1, height: 1 }} bold={true} align="center">{textPaper(ev.outlet.outlet_name, jml_paper)}</Text>
            <Text align="center">{ev.outlet.address !== "" && ev.outlet.address !== null ? textPaper(ev.outlet.address, jml_paper) : "Komp Era Prima No G1 Jl Daan Mogot KM 21, Tangerang"}</Text>
            <Text bold={true} align="center">{ev.transaction_code}</Text>
            <Br />
            <Line />
            <Text align="center">CETAK ULANG</Text>
            <Line />
            <Row left="Kasir     : " right={textRight(ev.cashier.detail_user.first_name + ' ' + ev.cashier.detail_user.last_name)} />
            <Row left="Transaksi : " right={dateTime} />
            <Row left="Pelanggan : " right={textRight(customer)} />
            <Line />
            {
                ev.product.map((key, i) => {
                    var price = posPriceNum(key);
                    var discount_percent = posPriceDiscountPercent(key);
                    var discount_price = Number(posPriceDiscountPrice(key));
                    var subtotal = Number(price) * key.qty;
                    if (key.edit_price !== undefined) {
                        var price = key.edit_price;
                        var discount_percent = key.edit_discount_percent;
                        var discount_price = posPriceBeforeDiscountNum(key) - key.edit_price;
                        var subtotal = Number(price) * key.qty;
                    }
                    // total_qty += key.qty;
                    // if (key.bonus !== undefined && key.bonus !== null) {
                    //     if (key.bonus.min_order === key.qty) {
                    //         if (key.bonus.bonus_product !== null) {
                    //             total_qty += 1;
                    //         }
                    //     }
                    // }
                    var productName = key.product.product_name + ' ' + (key.variant_name != null ? key.variant_product.variant_name : '');
                    return (
                        <div key={i}>
                            {
                                key.status_bonus !== undefined && key.status_bonus !== null ?
                                    key.status_bonus === true ?
                                        <>
                                            <Text bold={true}>{'(Bonus) ' + key.product.product_name + ' ' + (key.variant_name != null ? key.variant_product.variant_name : '')}</Text>
                                            <Row left={key.qty + (key.product.product_satuans.length > 0 ? key.product.product_satuans[0].satuan : '') + " x " + currencyFormatPrint(0)} right={currencyFormatPrint(0)} />
                                        </>
                                        :
                                        null
                                    :
                                    <>
                                        <Text bold={true}>{textPaper(productName, jml_paper)}</Text>
                                        <Row left={key.qty + (key.product.product_satuans.length > 0 ? key.product.product_satuans[0].satuan : '') + " x " + currencyFormatPrint(price)} right={currencyFormatPrint(subtotal)} />
                                        <Row left="Diskon" right={currencyFormatPrint(discount_price) + '(' + discount_percent + ')'} />
                                        {
                                            key.bonus !== undefined && key.bonus !== null ?
                                                key.qty >= key.bonus.min_order ?
                                                    key.bonus.bonus_product !== null ?
                                                        <>
                                                            <Text bold={true}>{'(Bonus) ' + key.bonus.bonus_product.variant_product.product.product_name + ' ' + key.bonus.bonus_product.variant_product.variant_name}</Text>
                                                            <Row left={bonusPCS(key) + (key.bonus.bonus_product.variant_product.product.product_satuan !== null ? key.bonus.bonus_product.variant_product.product.product_satuan.satuan : '') + " x " + currencyFormatPrint(0)} right={currencyFormatPrint(0)} />
                                                        </>
                                                        :
                                                        null
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                    </>
                            }
                            {ev.product.length < (i + 1) && <Br />}
                        </div>
                    )
                })
            }
            <Line />
            <Text size={{ width: 1.1, height: 1.1 }} bold={true} align="right" >Total : {currencyFormatPrint(ev.data.grand_total)}</Text>
            <Text bold={true} align="right">+ Termasuk Pajak</Text>
            <Line />
            {
                ev.data.payment_method === 'tunai' ?
                    <Row left="TUNAI : " right={currencyFormatPrint(ev.data.payment)} />
                    :
                    ev.data.payment_method === 'tunai_point' || ev.data.payment_method === 'point' ?
                        <>
                            <Row left="TUNAI : " right={currencyFormatPrint(ev.data.payment)} />
                            <Row left="POINT : " right={"-"+currencyFormatPrint(ev.data.point_redeem)} />
                        </>
                        :
                        ev.data.payment_method === 'card_point' ?
                            <>
                                <Row left={ev.data.payment_name + ' :'} right={currencyFormatPrint(ev.data.payment)} />
                                <Row left="POINT : " right={"-"+currencyFormatPrint(ev.data.point_redeem)} />
                            </>
                            :
                            <Row left={ev.data.payment_name + ' :'} right={currencyFormatPrint(ev.data.grand_total)} />
            }
            {
                ev.data.voucher_price !== null && Number(ev.data.voucher_price) !== 0 ?
                    <Row left={'Voucher :'} right={currencyFormatPrint(ev.data.voucher_price)} />
                    :
                    null
            }
            <Line />
            <Row left="Kembali : " right={currencyFormatPrint(ev.data.cashback)} />
            <Line />
            <Text align="center">Barang yang sudah dibeli tidak</Text>
            <Text align="center">bisa ditukar / dikembalikan</Text>
            <Line />
            <Text align="center">Website : www.boldestore.com</Text>
            <Cut />
        </Printer>
    );
    const data = await render(receipt);
    const w = serialPort.writable?.getWriter();
    if (w != null) {
        await w.write(data);
        w.releaseLock();
    }
}

export const writePrintHistory = async (serialPort, ev) => {
    var dt = new Date(ev.product.created_at);
    var dateTime = dateFormat(dt);
    var customer = ev.customer !== undefined && ev.customer !== null ? ev.customer.name : 'Non Membership';
    // var total_qty = 0;
    const receipt = (
        <Printer type="epson" width={jml_paper}>
            <Image align="center" src="assets/bolde2.png" />
            <Br />
            <Text size={{ width: 1, height: 1 }} bold={true} align="center">{textPaper(ev.outlet.outlet_name, jml_paper)}</Text>
            <Text align="center">{ev.outlet.address !== "" && ev.outlet.address !== null ? textPaper(ev.outlet.address, jml_paper) : "Komp Era Prima No G1 Jl Daan Mogot KM 21, Tangerang"}</Text>
            <Text bold={true} align="center">{ev.product.transaction_code}</Text>
            <Br />
            <Line />
            <Text align="center">CETAK ULANG</Text>
            <Line />
            <Row left="Kasir     : " right={textRight(ev.cashier.detail_user.first_name + ' ' + ev.cashier.detail_user.last_name)} />
            <Row left="Transaksi : " right={dateTime} />
            <Row left="Pelanggan : " right={textRight(customer)} />
            <Line />
            {
                ev.product.transaction_products.map((key, i) => {
                    var price = key.discount !== null ? key.discount.price_after_discount : key.price;
                    var discount_percent = key.discount !== null ? Number(key.discount.discount_percent) + "%" : 0;
                    var discount_price = key.discount !== null ? key.discount.discount_price : 0;

                    // total_qty += key.qty;
                    // if (key.bonus !== undefined && key.bonus !== null) {
                    //     if (key.bonus.min_order === key.qty) {
                    //         if (key.bonus.bonus_product !== null) {
                    //             total_qty += 1;
                    //         }
                    //     }
                    // }
                    var productName = key.product.product_name + ' ' + (key.variant_product.variant_name !== null ? key.variant_product.variant_name : '');
                    return (
                        <div key={i}>
                            {
                                key.status_bonus !== undefined && key.status_bonus !== null ?
                                    key.status_bonus === true ?
                                        <>
                                            <Text bold={true}>{'(Bonus) ' + key.product.product_name + ' ' + (key.variant_product.variant_name !== null ? key.variant_product.variant_name : '')}</Text>
                                            <Row left={key.qty + (key.product.product_satuans.length > 0 ? key.product.product_satuans[0].satuan : '') + " x " + currencyFormatPrint(0)} right={currencyFormatPrint(0)} />
                                        </>
                                        :
                                        null
                                    :
                                    <>
                                        <Text bold={true}>{textPaper(productName, jml_paper)}</Text>
                                        <Row left={key.qty + (key.product.product_satuans.length > 0 ? key.product.product_satuans[0].satuan : '') + " x " + currencyFormatPrint(price)} right={currencyFormatPrint(key.subtotal)} />
                                        <Row left="Diskon" right={currencyFormatPrint(discount_price) + '(' + discount_percent + ')'} />
                                        {
                                            key.bonus !== undefined && key.bonus !== null ?
                                                key.qty >= key.bonus.min_order ?
                                                    key.bonus.bonus_product !== null ?
                                                        <>
                                                            <Text bold={true}>{'(Bonus) ' + key.bonus.bonus_product.variant_product.product.product_name + ' ' + (key.bonus.bonus_product.variant_product.variant_name !== null ? key.bonus.bonus_product.variant_product.variant_name : '')}</Text>
                                                            <Row left={bonusPCS(key) + (key.bonus.bonus_product.variant_product.product.product_satuan !== null ? key.bonus.bonus_product.variant_product.product.product_satuan.satuan : null) + " x " + currencyFormatPrint(0)} right={currencyFormatPrint(0)} />
                                                        </>
                                                        :
                                                        null
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                    </>
                            }
                            {ev.product.transaction_products.length < (i + 1) && <Br />}
                        </div>
                    )
                })
            }
            <Line />
            <Text size={{ width: 1.1, height: 1.1 }} bold={true} align="right" >Total : {currencyFormatPrint(Number(ev.product.grand_total))}</Text>
            <Text bold={true} align="right">+ Termasuk Pajak</Text>
            <Line />
            {ev.payment_method === 'tunai' ?
                <Row left="TUNAI : " right={currencyFormatPrint(ev.product.payment)} />
                :
                ev.payment_method === 'tunai_point' || ev.payment_method === 'point' ?
                    <>
                        <Row left="TUNAI : " right={currencyFormatPrint(ev.product.payment)} />
                        <Row left="POINT : " right={"-"+currencyFormatPrint(ev.product.point_redeem)} />
                    </>
                    :
                    ev.payment_method === 'card_point' ?
                        <>
                            <Row left={ev.product.device_payment + ' :'} right={currencyFormatPrint(ev.product.grand_total)} />
                            <Row left="POINT : " right={"-"+currencyFormatPrint(ev.product.point_redeem)} />
                        </>
                        :
                        <Row left={ev.product.device_payment + ' :'} right={currencyFormatPrint(ev.product.grand_total)} />
            }
            {
                ev.product.voucher_price !== null && Number(ev.product.voucher_price) !== 0 ?
                    <Row left={'Voucher :'} right={currencyFormatPrint(ev.product.voucher_price)} />
                    :
                    null
            }
            <Line />
            <Row left="Kembali : " right={currencyFormatPrint(ev.product.payment - ev.product.grand_total)} />
            <Line />
            <Text align="center">Barang yang sudah dibeli tidak</Text>
            <Text align="center">bisa ditukar / dikembalikan</Text>
            <Line />
            <Text align="center">Website : www.boldestore.com</Text>
            <Cut />
        </Printer>
    );
    const data = await render(receipt);
    const w = serialPort.writable?.getWriter();
    if (w != null) {
        await w.write(data);
        w.releaseLock();
    }
}

export const writePrintReturn = async (serialPort, ev) => {
    var date = new Date();
    var dateTime = dateFormat(date);
    var customer = ev.transaction.customer !== undefined && ev.transaction.customer !== null ? ev.transaction.customer.name : 'Non Membership';
    var total = 0;
    var total_qty = 0;
    var transCode = ev.return_code !== null ? ev.return_code : ev.transaction_code;
    const receipt = (
        <Printer type="epson" width={jml_paper}>
            <Image align="center" src="assets/bolde2.png" />
            <Br />
            <Text size={{ width: 1, height: 1 }} bold={true} align="center">{textPaper(ev.outlet.outlet_name, jml_paper)}</Text>
            <Text align="center">{ev.outlet.address !== "" && ev.outlet.address !== null ? textPaper(ev.outlet.address, jml_paper) : "Komp Era Prima No G1 Jl Daan Mogot KM 21, Tangerang"}</Text>
            <Text bold={true} align="center">{transCode}</Text>
            <Br />
            <Line />
            <Text align="center">RETUR TRANSAKSI</Text>
            <Line />
            <Row left="Diretur   : " right={textRight(ev.transaction.user.detail_user.first_name + ' ' + ev.transaction.user.detail_user.last_name)} />
            <Row left="Transaksi : " right={dateTime} />
            <Row left="Pelanggan : " right={textRight(customer)} />
            <Line />
            {
                ev.product_returns.map((key, i) => {
                    total_qty += Number(key.qty);
                    total += Number(key.subtotal);
                    var productName = key.product.product_name;
                    var price = key.discount !== null ? key.discount.price_after_discount : key.price;
                    var discount_percent = key.discount !== null ? key.discount.discount_percent + "%" : 0;
                    var discount_price = key.discount !== null ? key.discount.discount_price : 0;

                    return (
                        <div key={i}>
                            <>
                                <Text bold={true}>{textPaper(productName, jml_paper)}</Text>
                                <Row left={key.qty + (key.product.product_satuans.length > 0 ? key.product.product_satuans[0].satuan : '') + " x " + currencyFormatPrint(price)} right={currencyFormatPrint(key.subtotal)} />
                                <Row left="Diskon" right={currencyFormatPrint(discount_price) + '(' + discount_percent + ')'} />
                            </>
                            {ev.product_returns.length < (i + 1) && <Br />}
                        </div>
                    )
                })
            }
            <Line />
            <Row left="Total Barang Kembali : " right={total_qty.toString()} />
            <Line />
            <Text size={{ width: 1.1, height: 1.1 }} bold={true} align="right" >Dikembalikan : {currencyFormatPrint(total)}</Text>
            <Line />
            <Text align="center">Barang yang sudah dibeli tidak</Text>
            <Text align="center">bisa ditukar / dikembalikan</Text>
            <Line />
            <Text align="center">Website : www.boldestore.com</Text>
            <Cut />
        </Printer>
    );
    const data = await render(receipt);
    const w = serialPort.writable?.getWriter();
    if (w != null) {
        await w.write(data);
        w.releaseLock();
    }
}