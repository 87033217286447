
import { Alert, Button, Col, Form, Image, Modal, Row, Select, Table, Upload } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { apiCall } from '../../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';


function DataDisplay() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [form] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [previewBannerImage, setPreviewBannerImage] = useState(null);
    const [previewSlideImages, setPreviewSlideImages] = useState([]);
    const [slideBanner, setSlideBanner] = useState([]);
    const [outlet, setOutlet] = useState([]);

    // //Parameter
    const titlePage = 'Display';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Outlet',
            dataIndex: 'outlet',
            key: "outlet",
            render: (id, record, index) => {
                return record.outlet.outlet_name;
            },
        },
        {
            title: 'Banner',
            dataIndex: 'banner',
            key: "banner",
            render: (id, record, index) => {
                return <Image src={record.banner} preview={true} width={50} height={50} />
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi('GET', '/display?search=' + search + '&page=' + page.pagination.current, 'display');
        getApi("GET", '/outlet?nopage=true', 'outlet');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'display':
                        setData(res.data.rows);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.count
                            }
                        });
                        setLoading(false);
                        setLoadingBtn(false);
                        break;
                    case 'outlet':
                        var item_outlet = res.data;
                        var row2 = [];
                        for (let i = 0; i < item_outlet.length; i++) {
                            row2.push({
                                value: item_outlet[i].outlet_id,
                                label: item_outlet[i].outlet_name,
                            });
                        }
                        setOutlet(row2);
                        setLoading(false);
                        setLoadingBtn(false);
                        break;

                    default:
                        break;
                }
            } else {
                setLoading(false);
                setLoadingBtn(false);
            }
        }).catch((e) => {
            setLoading(false);
            setLoadingBtn(false);
        })
    }

    const postApi = (method, url, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/display/' + v.display_banner_id, v),
        });
    }

    const openModal = (type, data) => {
        if (type === 'edit') {
            setdataModal(data)
            form.setFieldsValue({
                outlet_id: data.outlet_id,
            })
            setPreviewBannerImage(data.banner);
            var row = [];
            for (let i = 0; i < data.banner_slides.length; i++) {
                const img_slide = data.banner_slides[i].banner_slide;
                row.push(img_slide);
            }
            setPreviewSlideImages(row);
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (values) => {
        var method = 'POST';
        var url = '/display';
        var arrData = {
            outlet_id: values.outlet_id,
            image_banner: values.banner ? values.banner.file : null,
        }

        if (dataModal != null) {
            arrData = {
                display_id: dataModal.display_id,
            }
            method = 'PUT';
            url = '/display/' + dataModal.display_id;
        } else {
            method = 'POST';
        }

        const form = new FormData();
        for (const name in arrData) {
            form.append(name, arrData[name]);
        }

        for (let i = 0; i < slideBanner.length; i++) {
            form.append('image_slide', slideBanner[i]);
        }

        postApi(method, url, form);
    }

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        setPreviewBannerImage(null);
        setPreviewSlideImages([]);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleChangeBanner = (info) => {
        getBase64(info.file, (url) => {
            setPreviewBannerImage(url);
        });
    };

    const handleChangeSlide = (info) => {
        setSlideBanner([...slideBanner, info.file]);
        getBase64(info.file, (url) => {
            setPreviewSlideImages([...previewSlideImages, url]);
        });
    };

    const handleDeleteImage = (index) => {
        const newSlideImages = previewSlideImages.filter((_, i) => i !== index);
        setPreviewSlideImages(newSlideImages);
        
        var cek_length = previewBannerImage.length - slideBanner.length;
        var cekData = index - cek_length;
        if(cekData >= 0){
            const newSlideBanner = slideBanner.filter((_, i) => i !== index);
            setSlideBanner(newSlideBanner);
        }
    };

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Display</h3>
            <Row>
                <Col span={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Search
                        disabled={loadingBtn}
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button disabled={loadingBtn} style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Display</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Form Display" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form_display" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="outlet_id"
                        label="Pilih Outlet"
                        rules={[
                            {
                                required: true,
                                message: 'Pilih Outlet kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                marginBottom: 10
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder="Pilih Outlet"
                            options={outlet} />
                    </Form.Item>
                    <Form.Item name="banner" label="Banner">
                        <Upload
                            name="banner"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={() => false}
                            onChange={handleChangeBanner}
                        >
                            {previewBannerImage ? (
                                <Image preview={false} src={previewBannerImage} alt="Banner Preview" style={{ width: '100%' }} />
                            ) : (
                                <div>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag banner image to this area to upload</p>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>
                    <Form.Item name="banner_slide" label="Banner Slide">

                        <Upload
                            name="banner_slide"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={() => false}
                            onChange={handleChangeSlide}
                            multiple
                        >
                            <div>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag slide images to this area to upload</p>
                            </div>
                        </Upload>


                        {previewSlideImages.length > 0 && (
                            <Row gutter={16} style={{ marginTop: '20px' }}>
                                {previewSlideImages.map((image, index) => (
                                    <Col span={6} key={index} style={{ position: 'relative' }}>
                                        <Image preview={false} src={image} alt={`Slide Image ${index}`} style={{ width: '100%' }} />
                                        <Button
                                            type="primary"
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            onClick={() => handleDeleteImage(index)}
                                            style={{
                                                position: 'absolute',
                                                top: 10,
                                                right: 10,
                                                background: 'red',
                                                border: 'none',
                                                color: 'white'
                                            }}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default DataDisplay;