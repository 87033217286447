

const INITIAL_STATE = {
	user: null,
	menu: null,
	token:null
}

const userReducers = (state = INITIAL_STATE, action = {}) => {
	switch (action.type) {
		case "MENU_USER":
			return {
				...state,
				user: action.data,
				menu: action.menu,
				token: action.token
			};
		case "UPDATE_MENU":
			return {
				...state,
				menu: action.menu,
			};
		case "UPDATE_USER":
			return {
				...state,
				user: action.data,
			};
		case "UPDATE_TOKEN":
			return {
				...state,
				token: action.token
			};
		default:
			return state;
	}
};

export default userReducers;