
export function textPaper(text, jml_paper) {
    let formattedText = text;
    if (text.length > jml_paper) {
        const lastSpaceIndex = text.lastIndexOf(' ', jml_paper);
        if (lastSpaceIndex !== -1) {
            formattedText = text.substring(0, lastSpaceIndex) + '\n' + text.substring(lastSpaceIndex + 1);
        } else {
            // Jika tidak ada spasi, tambahkan newline setelah karakter maksimal
            formattedText = text.substring(0, jml_paper) + '\n' + text.substring(jml_paper);
        }
    }
    return formattedText;
}
