import { Card, Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import Headers from './Header/Headers';
import MenuSideBars from './SideBar/MenuSideBars';

function MainLayout({ children }) {
    return (
        <Layout>
            <Headers />
            <Layout>
                <MenuSideBars />
                <Layout
                    style={{
                        padding: '0 24px 24px',
                    }}
                >
                    <Content
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <Card>
                            {children}
                        </Card>
                    </Content>
                </Layout>
            </Layout>
            {/* <Footers /> */}
        </Layout>
    );
}

export default MainLayout;