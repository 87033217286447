
import { Alert, Button, Col, Modal, Row, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { PlusOutlined, EditOutlined, DeleteOutlined, SyncOutlined, LoadingOutlined } from '@ant-design/icons';
import { apiCall } from '../../../API/RestApi';
import Search from 'antd/es/input/Search';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { modalError } from '../../../Component/Modal/ModalLayout';


function ListGroupProductCategory() {
    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [load, setLoad] = useState(0);

    // //Parameter
    const titlePage = 'Produk Kategori Harga';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nomor',
            dataIndex: 'name',
            key: "name",
            sorter: true,
        },
        {
            title: 'Tanggal Mulai',
            dataIndex: 'start_date',
            key: "start_date",
            sorter: true,
        },
        {
            title: 'Kategori Harga',
            dataIndex: 'price_category_id',
            key: "price_category_id",
            sorter: true,
            render: (id, record, index) => {
                return record.price_category.name
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: "description",
            sorter: true,
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'approvalStatus',
        //     key: "approvalStatus",
        //     sorter: true,
        // },
        {
            title: 'Action',
            dataIndex: 'action',
            key: "action",
            sorter: true,
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => navigate('/formProductCategory', { state: { item: record } })}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi("GET", '/groupcategoryprice?search=' + search + '&page=' + page.pagination.current, 'all');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert, load]);


    const getApi = (method, url, type, data = null) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setTextAlert(res.message);
                switch (type) {
                    case 'all':
                        setData(res.data.rows);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.count
                            }
                        });
                        setLoading(false);
                        break;

                    default:
                        setIsAlert(true)
                        setLoad(load + 1)
                        setLoading(false);
                        setLoadingBtn(false);
                        break;
                }
            } else {
                switch (type) {
                    case 'del':
                        setLoading(false);
                        modalError(res.data.d[0]);
                        break;
                
                    default:
                        setLoading(false);
                        modalError(res.message);
                        break;
                }
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => {
                setTextAlert('Berhasil Hapus Data');
                getApi('DELETE', '/productcategorynew/' + v.group_category_price_id, 'del')
            },
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const syncProductAccurate = () => {
        setLoadingBtn(true);
        setLoading(true);
        getApi('GET','/accurate/loadpriceadjustment?page=1&pageSize=100','async');
        setLoadingBtn(true);
        setLoading(true);
        getApi('GET','/accurate/setdiscountprice','async');
    }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Produk Kategori Harga</h3>
            <Row style={{ marginBottom: 10 }}>
                <Col span={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button style={{ marginBottom: 10, marginRight: 10 }} type="primary" onClick={() => syncProductAccurate()}>
                        {
                            loadingBtn === true ?
                                <LoadingOutlined />
                                :
                                <SyncOutlined />
                        }
                        Sync Accurate
                    </Button>
                    <Search
                        disabled={loadingBtn}
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button disabled={loadingBtn} style={{ marginBottom: 10 }} type="primary" onClick={() => navigate('/formProductCategory')}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row >
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    );
}

export default ListGroupProductCategory;