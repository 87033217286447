
import { forwardRef } from 'react';
import { currencyFormat } from '../../Utils/formatCurrency';
import { dateFormat } from '../../Utils/formatDate';

const FormatPrintDailyReport = forwardRef((props, ref) => {
    return (
        <div ref={ref} style={{ margin: 10 }}>
            <h2 style={{textAlign:"center"}}>Laporan Harian Per Outlet</h2>
            <table className='tbl_border'>
                <tr>
                    <th className='tbl_border'><b>No.</b></th>
                    <th className='tbl_border'><b>Tanggal</b></th>
                    {/* <th className='tbl_border'><b>Kode Outlet</b></th> */}
                    <th className='tbl_border'><b>Nama Outlet</b></th>
                    <th className='tbl_border'><b>Status Outlet</b></th>
                    <th className='tbl_border'><b>Total Transaksi</b></th>
                    <th className='tbl_border'><b>Total Pendapatan</b></th>
                </tr>
                {
                    props.data.map((key, i) => {
                        var total = 0;
                        key.transactions.forEach(item => {
                            total += Number(item.grand_total);
                        });
                        return (
                            <tr key={i}>
                                <td className='tbl_border'>{i + 1}</td>
                                <td className='tbl_border'>{key.transactions.length > 0 ? dateFormat(key.transactions[0].created_at) : '-'}</td>
                                {/* <td className='tbl_border'>{key.outlet_code}</td> */}
                                <td className='tbl_border'>{key.outlet_name}</td>
                                <td className='tbl_border'>{key.isClosed === true ? 'Tutup' : 'Buka'}</td>
                                <td className='tbl_border'>{key.transactions.length} Transaksi</td>
                                <td className='tbl_border'>{currencyFormat(total)}</td>
                            </tr>
                        );
                    })
                }
            </table>
        </div>
    );
});

export default FormatPrintDailyReport;