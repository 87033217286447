import moment from "moment";


export function dateFormat(v) {
    var dt;
    if (!dateIsValid(new Date(v))) {  // Directly validate using Date constructor
        var [date, time] = v.split('T');
        var datetime = `${date} ${time.split('.')[0]}`;
        dt = new Date(datetime);
    } else {
        dt = new Date(v);  // Ensure it's a Date object
    }
    return moment(dt).format('YYYY-MM-DD HH:mm:ss');
}

 function dateIsValid(date) {
    return date instanceof Date && !isNaN(date);
  }