
import { Alert, Button, Col, Modal, Row, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import { dateFormat } from '../../Utils/formatDate';
import { useNavigate } from 'react-router-dom';


function ProductPackage() {
    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Paket Produk';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama Paket',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'Tanggal',
            dataIndex: 'created_at',
            key: "created_at",
            render: (id, record, index) => { return dateFormat(record.created_at); },

        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => navigate('/formProductPackage', { state: { item: record } })}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = () => {
        setLoading(true);
        const dataReq = {
            method: "GET",
            url: '/productpackage?search=' + search + '&page=' + page.pagination.current,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setData(res.data.rows);
                setPage({
                    ...page,
                    pagination: {
                        ...page.pagination,
                        total: res.data.count
                    }
                });
                setLoading(false);
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = (method, values) => {
        setLoading(true);
        setIsAlert(false);
        var url = '/productpackage';
        if (method === 'DELETE') {
            url = '/productpackage/' + values.group_package_id;
        }
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', v),
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Paket Produk</h3>
            <Row>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { navigate('/formProductPackage') }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    );
}

export default ProductPackage;