import { Modal, Select, Table, Button, Tabs, Image, Form, Input, InputNumber, Upload, message } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { apiCall } from "../../API/RestApi";
import { currencyFormat } from "../../Utils/formatCurrency";
import { useSelector } from "react-redux";
import TabPane from "antd/es/tabs/TabPane";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { FileTextOutlined } from '@ant-design/icons';
import { dateFormat } from "../../Utils/formatDate";
import { formatNumber } from "../../Utils/formatNumber";
const { Dragger } = Upload;

function VerificationPoint() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);
    const [outlet, setOutlet] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [valueOutlet, setValueOutlet] = useState();
    const [valStatus, setValStatus] = useState();
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const [isModal, setIsModal] = useState(false);
    const [valueModal, setValueModal] = useState(null);
    const [imageReceiptUrl, setImageReceiptUrl] = useState(null);
    const [imageReceiptFile, setImageReceiptFile] = useState(null);

    const [dataToExcel, setDataToExcel] = useState([]);

    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const titlePage = 'Verifikasi Point';
    const list_status = [
        {
            value: 'Process',
            label: 'Menunggu Konfirmasi'
        },
        {
            value: 'Approved',
            label: 'Telah Disetujui'
        },
        {
            value: 'Rejected',
            label: 'Telah Ditolak'
        },
    ];
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'No. Struk',
            dataIndex: 'transaction_code',
            key: "transaction_code",
            sorter: true,
            render: (id, record) => { return record.transaction.transaction_code; },
        },
        {
            title: 'Tanggal',
            dataIndex: 'created_at',
            key: "created_at",
            sorter: true,
            render: (id, record) => { return dateFormat(record.created_at); },
        },
        {
            title: 'Nama Outlet',
            dataIndex: 'outlet_name',
            key: "outlet_name",
            sorter: true,
            render: (id, record) => { return record.transaction.outlet.outlet_name },
        },
        {
            title: 'Nama Customer',
            dataIndex: 'name',
            key: "name",
            sorter: true,
            render: (id, record, index) => { return record.customer.name },
        },
        {
            title: 'Total Transaksi',
            dataIndex: 'grand_total',
            key: "grand_total",
            sorter: true,
            render: (id, record, index) => { return currencyFormat(record.transaction.grand_total); },
        },
        {
            title: 'Point',
            dataIndex: 'point',
            key: "point",
            sorter: true,
            render: (id, record, index) => {
                return formatNumber(record.point.point) + ' Point';
            },
        },
        {
            title: 'Lampiran',
            dataIndex: 'receipt',
            key: "receipt",
            sorter: true,
            render: (id, record, index) => {
                return (
                    <>
                        <Image
                            preview={record.file_receipt !== null ? true : false}
                            src={record.file_receipt !== null ? record.file_receipt : '/assets/photo_empty.png'}
                            height={50}
                            width={50}
                        />
                    </>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.point.status === 'Approved') {
                    return (
                        <>
                            <b style={{ marginLeft: 5, color: "green" }}>Telah Disetujui</b>
                        </>
                    );
                } else if (record.point.status === 'Rejected') {
                    return (
                        <>
                            <b style={{ marginLeft: 5, color: "red" }}>Telah Ditolak</b>
                        </>
                    );
                } else {
                    return (
                        <>
                            <b style={{ marginLeft: 5, color: "orange" }}>Menunggu Konfirmasi</b>
                        </>
                    );
                }
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="default" style={{ marginRight: 10, marginBottom:10 }} onClick={() => openModal(record)}>
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        {
                            record.point.status === 'Process' ?
                                <>
                                    <Button type="primary" style={{ marginRight: 10, marginBottom:10 }} onClick={() => modalConfirm(record, 'agree')}>
                                        <b style={{ marginLeft: 5 }}>Setuju</b>
                                    </Button>
                                    <Button type="primary" danger style={{ marginRight: 10, marginBottom:10 }} onClick={() => modalConfirm(record, 'disagree')}>
                                        <b style={{ marginLeft: 5 }}>Tolak</b>
                                    </Button>
                                </>
                                :
                                null
                        }
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        var outlet = '';
        var status = '&status=Process';
        if (valueOutlet !== null && valueOutlet !== undefined) {
            outlet = '&outlet=' + valueOutlet;
        }

        if (valStatus !== null && valStatus !== undefined) {
            status = '&status=' + valStatus;
        }
        var url = '/pointverification?page=' + page.pagination.current + outlet  + status;
        getApi("GET", url, 'point');
        if (outlet.length === 0) {
            getApi('GET', '/outlet?nopage=true', 'outlet');
        }
        if (customer.length === 0) {
            getApi('GET', '/customer?nopage=true', 'customer');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page)]);

    const getApi = (method, url, type) => {
        setLoading(true);

        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                if (type === 'point') {
                    var row = [];
                    for (let i = 0; i < res.data.count; i++) {
                        var item = res.data.rows[i];
                        row.push({
                            'No': i + 1,
                            'Kode Transaksi': item.transaction.transaction_code,
                            'Tanggal': dateFormat(item.created_at),
                            'Nama Outlet': item.transaction.outlet.outlet_name,
                            'Nama Customer': item.customer.name,
                            'Total Transaksi': item.transaction.grand_total,
                            'Point': item.point.point,
                            'Status': 'In Process'
                        });
                    }
                    setDataToExcel(row);
                    setData(res.data.rows);
                    setPage({
                        ...page,
                        pagination: {
                            ...page.pagination,
                            total: res.data.count
                        }
                    });
                    setLoading(false);
                } else if (type === 'outlet') {
                    var o = [];
                    for (let i = 0; i < res.data.length; i++) {
                        o = [...o, {
                            value: res.data[i].outlet_id,
                            label: res.data[i].outlet_name
                        }];
                    }
                    setOutlet(o);
                } else if (type === 'customer') {
                    var c = [];
                    for (let i = 0; i < res.data.length; i++) {
                        c = [...c, {
                            value: res.data[i].customer_id,
                            label: res.data[i].phone + ' (' + res.data[i].name + ')'
                        }];
                    }
                    setCustomer(c);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
        })
    }

    const postApi = (method, url, values, type, header = null) => {
        setLoading(true);
        var headers;
        if (header !== null) {
            headers = header;
        } else {
            headers = {
                Authorization: 'Bearer ' + token
            }
        }
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: headers
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'status':
                        setPage({
                            pagination: {
                                current: 1,
                                pageSize: 10,
                            },
                        });
                        setLoading(false);
                        break;

                    case 'inputverification':
                        setPage({
                            pagination: {
                                current: 1,
                                pageSize: 10,
                            },
                        });
                        form.setFieldsValue({
                            transaction_code: null,
                            grand_total: null,
                            outlet_id: null,
                            customer_id: null,
                        })
                        setImageReceiptFile(null);
                        setImageReceiptUrl(null);
                        setLoading(false);
                        message.success(res.message);
                        closeModal();
                        break;

                    default:
                        setLoading(false);
                        break;
                }
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const modalConfirm = (v, status) => {
        if (status === 'agree') {
            Modal.success({
                title: 'NOTICE',
                content: 'Anda ingin menyetujui point member ini?',
                cancelText: "Tidak",
                okText: 'Setuju',
                okCancel: true,
                onOk: () => {
                    postApi("POST", '/transaction/changestatuspoint', {
                        verify_point_id:v.verify_point_id,
                        point_id: v.point_id,
                        customer_id: v.customer.customer_id,
                        point: v.point.point,
                        grand_total:v.transaction.grand_total,
                        status: 'agree'
                    }, 'status');
                },
            });
        } else {
            Modal.confirm({
                title: 'NOTICE',
                content: 'Anda ingin menolak point member ini?',
                cancelText: "Tidak",
                okText: 'Tolak',
                okCancel: true,
                onOk: () => postApi("POST", '/transaction/changestatuspoint', { point_id: v.point_id, status: 'disagree' }, 'status'),
            });
        }
    }

    const findSearch = () => {
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const onChangeTabs = (status) => {
        setValStatus(status);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = () => {
        //parameter
        var apiData = dataToExcel;
        var fileName = 'riwayat_verifikasi_point'

        //header
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

        //convert Excel
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const op = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(op, fileName + fileExtension);
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img.originFileObj);
    };

    const onUploadReceipt = (info) => {
        setImageReceiptFile(info.file.originFileObj);
        getBase64(info.file, (url) => {
            setImageReceiptUrl(url);
        });
    }

    const openModal = (record) => {
        console.log(record)
        form.setFieldsValue({
            transaction_code: record.transaction.transaction_code,
            grand_total: record.transaction.grand_total,
            point: record.point.point,
            status: record.point.status,
            outlet_id: record.transaction.outlet.outlet_id,
            customer_id: record.customer.customer_id,
        })
        setImageReceiptUrl(record.file_receipt)
        setValueModal(record);
        setIsModal(true);
    }

    const closeModal = () => {
        form.setFieldsValue({
            transaction_code: null,
            grand_total: null,
            outlet_id: null,
            customer_id: null,
        })
        setValueModal(null);
        setImageReceiptUrl(null);
        setIsModal(false);
    }

    const save = (ev) => {
        if (imageReceiptUrl !== null && imageReceiptUrl !== undefined) {
            if (imageReceiptFile !== null && imageReceiptFile !== undefined) {
                ev.image = imageReceiptFile;
            }
            if (valueModal !== null && valueModal !== undefined) {
                ev.transaction_id = valueModal.transaction_id;
                ev.point_id = valueModal.point.point_id
            }
            //convert json to form
            const form = new FormData();
            for (const name in ev) {
                form.append(name, ev[name]);
            }
            var header = {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'multipart/form-data',
            }
            postApi('PUT', '/inputverification', form, 'inputverification', header);
        } else {
            message.error('Upload Struk terlebih dahulu!');
        }
    }

    const renderTabs = (type) => {
        return (
            <>
                <div style={{ width: 200 }}>
                    <Select
                        showSearch
                        style={{
                            width: 200,
                            marginBottom: 10
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        placeholder="Pilih Outlet"
                        onChange={(ev) => setValueOutlet(ev)}
                        options={outlet} />
                    <br />
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => findSearch()}>
                        <b style={{ marginLeft: 5 }}>Cari</b>
                    </Button>
                    {
                        type === 'history' &&
                        <Button style={{ marginBottom: 10, marginLeft: 10 }} type="primary" onClick={() => exportToCSV()}>
                            <b style={{ marginLeft: 5 }}>Export Excel</b>
                        </Button>
                    }
                </div>
                <Table
                    size="middle"
                    columns={columns}
                    dataSource={data}
                    pagination={page.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </>
        )
    }

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            <h3>Verifikasi Point Admin</h3>
            <Tabs onChange={onChangeTabs}>
                <TabPane tab="Riwayat" key="Process">
                    {renderTabs('history')}
                </TabPane>
                <TabPane tab="Disetujui" key="Approved">
                    {renderTabs('agree')}
                </TabPane>
                <TabPane tab="Ditolak" key="Rejected">
                    {renderTabs('disagree')}
                </TabPane>
            </Tabs>
            <Modal title="Form Verificaton" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form_input_verification" layout="vertical" onFinish={save}>
                    <Form.Item
                        name="transaction_code"
                        label="No. Struk"
                        rules={[
                            {
                                required: true,
                                message: 'No. Struk kosong!'
                            },
                        ]}
                    >
                        <Input placeholder='No. Struk' />
                    </Form.Item>
                    <Form.Item
                        name="grand_total"
                        label="Grand Total"
                        rules={[
                            {
                                required: true,
                                message: 'Grand Total kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            // value={pay}
                            placeholder="Grand Total"
                        // onChange={(val) => setPay(val)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="customer_id"
                        label="No. HP"
                        rules={[
                            {
                                required: true,
                                message: 'No. HP kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder="Pilih Outlet"
                            options={customer}
                        />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="Status"
                        rules={[
                            {
                                required: true,
                                message: 'Status kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder="Pilih Outlet"
                            options={list_status}
                        />
                    </Form.Item>
                </Form>
                <p><b style={{ color: 'red' }}>*</b> Upload Struk</p>
                {
                    imageReceiptUrl !== null && imageReceiptUrl !== undefined ?
                        <div style={{
                            width: 300,
                            height: 230,
                            overflow: 'hidden',
                            // display: 'flex'
                        }}>
                            <Image
                                src={imageReceiptUrl}
                                style={{
                                    width: 300,
                                    height: 230,    
                                    objectFit: "contain",
                                }} />
                        </div>
                        :
                        <Dragger onChange={(ev) => onUploadReceipt(ev)}>
                            <p className="ant-upload-drag-icon">
                                <FileTextOutlined />
                            </p>
                            <p className="ant-upload-text">Upload Struk disini</p>
                            <p className="ant-upload-hint">
                                Upload Struk untuk verifikasi transaksi dan point
                            </p>
                        </Dragger>
                }
                {
                    imageReceiptUrl !== null && imageReceiptUrl !== undefined &&
                    <div style={{ marginTop: 10, textAlign: "left" }}>
                        <Button type='default' danger style={{ marginRight: 5 }} onClick={() => setImageReceiptUrl(null)}>
                            <div style={{ fontSize: 12 }}>Hapus Gambar</div>
                        </Button>
                    </div>
                }
            </Modal>
        </div>
    );
}


export default VerificationPoint;