
import { Alert, Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Spin, Table, TreeSelect } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { apiCall } from '../../../API/RestApi';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { currencyFormat } from '../../../Utils/formatCurrency';

const { SHOW_PARENT } = TreeSelect;

function FormProductCategory() {
    const token = useSelector((state) => state.user.token);
    const location = useLocation();

    const [data, setData] = useState([]);
    const [outlet, setOutlet] = useState([]);



    const [statusForm, setStatusForm] = useState('add');
    const [categoryPrice, setCategoryPrice] = useState([]);
    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [statusShow, setStatusShow] = useState(false);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [formGroup] = Form.useForm();
    const [isStatusInput, setStatusInput] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [load, setLoad] = useState(0);

    const [statusColumn, setStatusColumn] = useState("Harga");

    const [price, setPrice] = useState();
    const [discount, setDiscount] = useState();
    const [discount2, setDiscount2] = useState();
    // //Parameter
    const titlePage = 'Produk Kategori Harga';
    const columns_discount = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Produk',
            dataIndex: 'product_name',
            key: "product_name",
            sorter: true,
            render: (id, record, index) => {
                if (record.variant_product !== undefined)
                    return record.variant_product.product.product_name;
                else
                    return record.product.product_name;
            },
        },
        {
            title: 'Diskon %',
            dataIndex: 'discount',
            key: "discount",
            sorter: true,
            render: (id, record, index) => {
                if (record.variant_product !== undefined)
                    return <InputNumber
                        maxLength={3}
                        // disabled={true}
                        addonBefore="%"
                        key={(page.pagination.current - 1) * 10 + index}
                        value={PriceDiscount(record, 'discount')}
                        defaultValue={PriceDiscount(record, 'discount')}
                        onChange={(ev) => onInputTableUpdated(ev, index, 'discount_percent', record)}
                    />;
                else
                    return <InputNumber
                        maxLength={3}
                        addonBefore="%"
                        key={(page.pagination.current - 1) * 10 + index}
                        value={record.discount_percent}
                        defaultValue={record.discount_percent !== undefined ? record.discount_percent : null}
                        onChange={(ev) => onInputTable(ev, index, 'discount_percent')}
                    />;
            },
        },
        {
            title: 'Diskon Ke 2',
            dataIndex: 'discount2',
            key: "discount2",
            sorter: true,
            render: (id, record, index) => {
                if (record.variant_product !== undefined)
                    return <InputNumber
                        maxLength={3}
                        // disabled={true}
                        defaultValue={PriceDiscount(record, 'discount2')}
                        value={PriceDiscount(record, 'discount2')}
                        key={(page.pagination.current - 1) * 10 + index}
                        style={{ width: '100%' }}
                        onChange={(ev) => onInputTableUpdated(ev, index, 'discount_percent_2', record)}
                    />;
                else
                    return <InputNumber
                        maxLength={3}
                        defaultValue={record.discount_percent_2 !== undefined ? record.discount_percent_2 : null}
                        value={record.discount_percent_2}
                        key={(page.pagination.current - 1) * 10 + index}
                        style={{ width: '100%' }}
                        onChange={(ev) => onInputTable(ev, index, 'discount_percent_2')}
                    />;
            },
        },
    ];

    const columns_price = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Produk',
            dataIndex: 'product_name',
            key: "product_name",
            sorter: true,
            render: (id, record, index) => {
                if (record.variant_product !== undefined)
                    return record.variant_product.product.product_name;
                else
                    return record.product.product_name;
            },
        },
        {
            title: 'Harga',
            dataIndex: 'price',
            key: "price",
            sorter: true,
            render: (id, record, index) => {
                return currencyFormat(record.price_old);
            },
        },
        {
            title: 'Harga Baru',
            dataIndex: 'price_new',
            key: "price_new",
            sorter: true,
            render: (id, record, index) => {
                if (record.variant_product !== undefined)
                    return <InputNumber
                        addonBefore="Rp."
                        // disabled={true}
                        defaultValue={PriceDiscount(record, 'price_new')}
                        value={PriceDiscount(record, 'price_new')}
                        key={(page.pagination.current - 1) * 10 + index}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        onChange={(ev) => onInputTableUpdated(ev, index, 'price', record)}
                    />
                else
                    return <InputNumber
                        addonBefore="Rp."
                        defaultValue={record.price !== undefined ? record.price : null}
                        value={record.price}
                        key={(page.pagination.current - 1) * 10 + index}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        onChange={(ev) => onInputTable(ev, index, 'price')}
                    />
            },
        },
    ];

    const typeAdjustmentArr = [
        {
            "value": "Harga",
            "label": "Harga"
        },
        {
            "value": "Diskon (%)",
            "label": "Diskon (%)"
        }
    ];


    useEffect(() => {
        if (location.state != null) {
            setStatusShow(true);
            setStatusForm('update')
            const { item } = location.state;
            setStatusColumn(item.salesAdjustmentTypeName);
            formGroup.setFieldsValue({
                price_category_id: item.price_category_id,
                salesAdjustmentTypeName: item.salesAdjustmentTypeName,
                start_date: moment(item.start_date),
                number: item.name,
                branch_id: item.branch_id,
                currency_name: item.currency_name
            });

            setStatusInput(true);
            // getApi("GET", '/productcategory?groupCategoryPriceId=' + item.group_category_price_id + '&page=' + page.pagination.current, 'all');
            getApi("GET", '/productcategory?groupCategoryPriceId=' + item.group_category_price_id + '&nopage=true', 'all');
        }
        if (outlet.length === 0) {
            getApi("GET", '/outlet?nopage=true', 'outlet');
        }
        if (categoryPrice.length === 0) {
            getApi("GET", '/pricecategory?nopage=true', 'pricecategory');
        }
        if (category.length === 0) {
            getApi("GET", '/category?nopage=true', 'category');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert, load]);

    const PriceDiscount = (item, type) => {
        switch (type) {
            case 'price':
                if (item.price_old !== undefined) {
                    return item.price_old;
                } else {
                    return item.variant_product.price;
                }
            case 'discount2':
                if (item.discount !== null)
                    if (Number(item.discount.discount_percent_2) > 0)
                        return item.discount.discount_percent_2;
                    else
                        return 0;
                break;

            case 'discount':
                if (item.discount !== null)
                    if (Number(item.discount.discount_percent) > 0)
                        return item.discount.discount_percent;
                    else
                        return 0;
                break;

            case 'price_new':
                return Number(item.price);

            default:
                break;
        }
    }

    const onInputTable = (ev, index, type) => {
        const newData = [...data];
        var i = (page.pagination.current - 1) * 10 + index;
        newData[i][type] = ev;
        setData(newData);
    }

    const onInputTableUpdated = (ev, index, type, item) => {
        const newData = [...data];
        var i = (page.pagination.current - 1) * 10 + index;
        switch (type) {
            case 'price':
                if (item.variant_product !== undefined) {
                    newData[i].variant_product.price = ev;
                    newData[i].price = ev;
                }
                break;
            case 'discount_percent_2':
                if (item.discount !== null)
                    if (Number(item.discount.discount_percent_2) > 0)
                        newData[i].discount.discount_percent_2 = ev;
                    else
                        newData[i].discount.discount_percent_2 = 0;
                break;

            case 'discount_percent':
                if (item.discount !== null)
                    if (Number(item.discount.discount_percent) > 0)
                        newData[i].discount.discount_percent = ev;
                    else
                        newData[i].discount.discount_percent = 0;
                break;
            default:
                break;
        }
        setData(newData);
    }

    const getApi = (method, url, type, datas = null) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: datas,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'outlet':
                        var p = [];
                        p.push({
                            value: null,
                            label: 'Semua Outlet'
                        });
                        for (let i = 0; i < res.data.length; i++) {
                            p.push({
                                key: res.data[i].branch_id,
                                value: res.data[i].branch_id,
                                label: res.data[i].outlet_name
                            });
                        }
                        setOutlet(p);
                        setLoading(false);
                        break;
                    case 'pricecategory':
                        var row = [];
                        var item = res.data.rows;
                        for (let i = 0; i < item.length; i++) {
                            row.push({
                                key: item[i].price_category_id,
                                value: item[i].price_category_id,
                                label: item[i].name,
                            })
                        }
                        setCategoryPrice(row);
                        setLoading(false);
                        break;
                    case 'all':
                        if (statusForm === 'update') {
                            const updatedData = res.data.rows.map(item => ({
                                ...item,
                                price_old: item.price,
                            }));
                            setData([...updatedData]);
                        } else {
                            setData(res.data.rows);
                        }
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.count
                            }
                        });
                        setLoading(false);
                        break;
                    case 'category':
                        var row2 = [];
                        var item2 = res.data;
                        for (let i = 0; i < item2.length; i++) {
                            row2.push({
                                key: item2[i].category_id,
                                value: item2[i].category_id,
                                label: item2[i].category_name,
                            })
                        }
                        setCategory(row2);
                        setLoading(false);
                        break;
                    case 'findCategoryInProduct':
                        const updatedData = res.data.rows.map(item => ({
                            ...item,
                            price_old: item.price,
                            price: null,
                            discount_percent: null,
                            discount_percent_2: null,
                        }));
                        setData([...updatedData]);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.count
                            }
                        });
                        setStatusShow(true);
                        setLoading(false);
                        break;

                    default:
                        setLoadingBtn(false);
                        setIsAlert(true)
                        setLoad(load + 1)
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const onFormFinish = () => {
        setLoadingBtn(true);
        const formHead = formGroup.getFieldsValue();
        formHead.start_date = moment(formHead).format('YYYY-MM-DD HH:mm:ss');
        const dataGroup = {
            ...formHead,
            data: []
        }
        var row = [];
        for (let i = 0; i < data.length; i++) {
            if (dataGroup.salesAdjustmentTypeName === 'Harga') {
                if (statusForm === 'update') {
                    // row.push(data[i]);
                    row.push({
                        price: data[i].price,
                        discount_price: data[i].discount_price,
                        price_after_discount: data[i].price_after_discount,
                        discount_id: data[i].discount_id,
                        bonus_id: data[i].bonus_id,
                        product_category_code: data[i].product_category_code,
                        salesAdjustmentType: data[i].salesAdjustmentType,
                        salesAdjustmentTypeName: data[i].salesAdjustmentTypeName,
                        number: data[i].number,
                        branch_id: data[i].branch_id,
                        start_date: data[i].start_date,
                        suspended: data[i].suspended,
                        product_category_price_id: data[i].product_category_price_id,
                    });
                } else {
                    if (data[i].price !== undefined) {
                        if (Number(data[i].price) > 0) {
                            row.push({
                                variant_product_id: data[i].variant_product_id,
                                price: data[i].price,
                                discount_percent: data[i].discount_percent,
                                discount_percent_2: data[i].discount_percent_2,
                            });
                        }
                    }
                }
            } else {
                if (statusForm === 'update') {
                    data[i].discount_percent = data[i].discount.discount_percent;
                    data[i].discount_percent_2 = data[i].discount.discount_percent_2;
                    row.push(data[i]);
                } else {
                    if (data[i].discount_percent !== undefined || data[i].discount_percent_2 !== undefined) {
                        if (Number(data[i].discount_percent) > 0 || Number(data[i].discount_percent_2) > 0) {
                            row.push({
                                variant_product_id: data[i].variant_product_id,
                                price: data[i].price,
                                discount_percent: data[i].discount_percent,
                                discount_percent_2: data[i].discount_percent_2,
                            });
                        }
                    }
                }
            }
        }
        if (dataGroup.branch_id === undefined) {
            dataGroup.branch_id = [null];
        }
        dataGroup.data = row;
        if (statusForm === 'update') {
            const { item } = location.state;
            dataGroup.group_category_price_id = item.group_category_price_id;
            getApi('PUT', '/productcategorynew', 'update', dataGroup);
            setTextAlert('Berhasil Rubah Data');
        } else {
            getApi('POST', '/productcategorynew', 'insert', dataGroup);
            setTextAlert('Berhasil Tambah Data');
        }
    }

    const findProductWithCategory = (ev) => {
        getApi("GET", '/productvariantlite?categoryId=' + ev, 'findCategoryInProduct');
    }

    const checkColumn = (ev = null) => {
        setStatusColumn(ev);
        const updatedData = data.map(item => ({
            ...item,
            price: null,
            discount_percent: null,
            discount_percent_2: null,
        }));
        setPrice(null);
        setDiscount(null);
        setDiscount2(null);
        setData(updatedData);
    }

    const inputAll = (ev, type) => {
        switch (type) {
            case 'price':
                const newPrice = Number(ev);
                var updatedData = [];
                if (statusForm === 'update') {
                    updatedData = data.map(item => ({
                        ...item,
                        variant_product: {
                            ...item.variant_product,
                            price: newPrice
                        },
                        price: newPrice
                    }));
                } else {
                    updatedData = data.map(item => ({
                        ...item,
                        price: newPrice
                    }));
                }
                setData(updatedData);
                break;

            case 'discount_percent':
                const discount = Number(ev);
                var updatedDis = [];
                if (statusForm === 'update') {
                    updatedDis = data.map(item => ({
                        ...item,
                        discount: {
                            ...item.discount,
                            discount_percent: discount
                        }
                    }));
                } else {
                    updatedDis = data.map(item => ({
                        ...item,
                        discount_percent: discount
                    }));
                }
                setData(updatedDis);
                break;
            case 'discount_percent_2':
                const discount2 = Number(ev);
                var updatedDis2 = [];
                if (statusForm === 'update') {
                    updatedDis2 = data.map(item => ({
                        ...item,
                        discount: {
                            ...item.discount,
                            discount_percent_2: discount2
                        }
                    }));
                } else {
                    updatedDis2 = data.map(item => ({
                        ...item,
                        discount_percent_2: discount2
                    }));
                }
                setData(updatedDis2);
                break;

            default:
                break;
        }
    };

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            {loadingBtn ?
                <div style={{ textAlign: 'center' }}>
                    <Spin size="large" />
                    <p>Saving...</p>
                </div>
                :
                (
                    <div>
                        <h3>Daftar Produk Kategori Harga</h3>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
                            <Button style={{ marginBottom: 10 }} type="primary" onClick={() => onFormFinish()}>
                                <b style={{ marginLeft: 5 }}>Simpan</b>
                            </Button>
                        </div>
                        <Form form={formGroup} name="form_group_category_price" layout="vertical">
                            <Row>
                                <Col span={11}>
                                    <Form.Item
                                        name="price_category_id"
                                        label="Pilih Kategori Harga"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Nama Kategori Harga kosong!'
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={categoryPrice}
                                        />
                                    </Form.Item>
                                    {
                                        statusForm === 'add' &&
                                        <>
                                            <Form.Item
                                                name="categoryPrice"
                                                label="Pilih Kategori Produk"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Pilih Kategori Produk kosong!'
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    style={{
                                                        width: "100%"
                                                    }}
                                                    placeholder="Pilih Kategori Produk"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    onChange={(ev) => findProductWithCategory(ev)}
                                                    options={category}
                                                />
                                            </Form.Item>
                                        </>
                                    }
                                    {
                                        statusForm === 'update' || formGroup.getFieldValue('categoryPrice') !== undefined ?
                                            <Form.Item
                                                name="salesAdjustmentTypeName"
                                                label="Tipe Penyesuaian"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Tipe Penyesuaian kosong!'
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={isStatusInput}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    onSelect={(ev) => checkColumn(ev)}
                                                    options={typeAdjustmentArr}
                                                />
                                            </Form.Item>
                                            :
                                            null
                                    }
                                </Col>
                                <Col span={2} />
                                <Col span={11}>
                                    <Form.Item
                                        name="start_date"
                                        label="Mulai Berlaku"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Mulai Berlaku kosong!'
                                            },
                                        ]}
                                    >
                                        <DatePicker disabled={isStatusInput} format="YYYY-MM-DD" placeholder="Pilih Tanggal" style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        name="branch_id"
                                        label="Pilih Cabang"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Pilih Cabang kosong!'
                                            },
                                        ]}
                                    >

                                        <TreeSelect
                                            disabled={isStatusInput}
                                            placement='bottomLeft'
                                            treeData={outlet}
                                            treeCheckable={true}
                                            showCheckedStrategy={SHOW_PARENT}
                                            allowClear
                                            dropdownMatchSelectWidth={false}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="currency_name"
                                        label="Mata Uang"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Mata Uang kosong!'
                                            },
                                        ]}
                                    >
                                        <Input disabled={true} value={"Indonesian Rupiah"} defaultValue={"Indonesian Rupiah"} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>

                        <Divider />
                        <Row>
                            <Col span={12}>
                            </Col>
                            <Col span={12}>
                                {
                                    formGroup.getFieldValue('salesAdjustmentTypeName') === "Harga" ?
                                        <>
                                            <div style={{ marginBottom: 5 }}>Harga</div>
                                            <InputNumber
                                                addonBefore="Rp."
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                style={{ width: '100%' }}
                                                value={price}
                                                onChange={(ev) => inputAll(ev, 'price')}
                                            />
                                        </>
                                        :
                                        <>
                                            <div style={{ marginBottom: 5 }}>Diskon</div>
                                            <InputNumber
                                                style={{ marginBottom: 10 }}
                                                maxLength={3}
                                                addonBefore="%"
                                                value={discount}
                                                onChange={(ev) => inputAll(ev, 'discount_percent')}
                                            />
                                            <div style={{ marginBottom: 5 }}>Diskon Ke 2</div>
                                            <InputNumber
                                                maxLength={3}
                                                addonBefore="%"
                                                value={discount2}
                                                onChange={(ev) => inputAll(ev, 'discount_percent_2')}
                                            />
                                        </>
                                }
                            </Col>
                        </Row>
                        {
                            statusShow === true ?
                                <div>
                                    <h3>Daftar Produk</h3>
                                    <Row style={{ marginBottom: 10 }}>
                                        <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                        </Col>
                                    </Row>
                                    {
                                        statusColumn === 'Harga' ?
                                            <Table
                                                size="middle"
                                                columns={columns_price}
                                                dataSource={data}
                                                loading={loading}
                                                onChange={handleTableChange}
                                                pagination={page.pagination}
                                            />
                                            :
                                            <Table
                                                size="middle"
                                                columns={columns_discount}
                                                dataSource={data}
                                                loading={loading}
                                                onChange={handleTableChange}
                                                pagination={page.pagination}
                                            />
                                    }
                                </div>
                                :
                                null
                        }
                    </div>
                )}
        </>
    );
}

export default FormProductCategory;