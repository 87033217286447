
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import './App.css';
import { store } from './Redux/stores/store';
import Router from './Route/Router';
import { useEffect, useRef } from 'react';
// import runOneSignal from './Utils/onesignal';
// import OneSignalReact from 'react-onesignal';
import { apiCall } from './API/RestApi';
import ServerConnectionChecker from './Utils/Connection/ServerConnectionChecker';

const App = () => {
  // const count = useRef(null);
  useEffect(() => {
    cekToken();
    // if (count.current == null) {
    //   runOneSignal();
    // }
    // getDataNotif();
    // return () => { count.current = 1; }
  }, []);

  function cekToken() {
    const dataReq = {
      method: "GET",
      url: '/checktoken',
    };
    apiCall(dataReq).then((res) => {
      if (res.statusAvailable === false) {
        const dataReq = {
          method: "PUT",
          url: '/token',
        };
        apiCall(dataReq).then((res2) => {
          console.log(res2)
        });
      }
    })
  }

  // function getDataNotif() {
  //   OneSignalReact.addListenerForNotificationOpened().then((res) => console.log(res)).catch((e) => console.log(e));
  // }
  return (
    <>
      <HelmetProvider>
        <Provider store={store}>
          <ServerConnectionChecker />
          <Router />
        </Provider>
      </HelmetProvider>
    </>
  );
}

export default App;
