import { urlOutlet } from "./globalUrl";


export function imageCheck(img, type) {
    var o = img.indexOf('http');
    var image;
    switch (type) {
        case 'outlet':
            image = urlOutlet + img;
            break;
        default:
            break;
    }
    if(o !== -1){
        image = img;
    }

    return image;
    
}