
const MENU_USER = (menu, data, token) => {
    return { type: 'MENU_USER', menu, data, token }
}
const UPDATE_MENU = (menu) => {
    return { type: 'UPDATE_MENU', menu }
}
const UPDATE_TOKEN = (data) => {
    return { type: 'UPDATE_TOKEN', data }
}
const UPDATE_USER = (data) => {
    return { type: 'UPDATE_USER', data }
}

export { 
    MENU_USER,
    UPDATE_MENU,
    UPDATE_TOKEN,
    UPDATE_USER
}